import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import slugify from "react-slugify"
import { options } from "../components/blogItem"

const useResolveBlogText = rawHTML => {
  return buildLayout(renderRichText(rawHTML, options))
}
function buildLayout(elements, i) {
  const leftColContent = elements.filter(
    e => e?.type === "h2" && e.props.className === "topic-title"
  )

  let leftContent = elements
    .filter((el, i) => el?.type === "h2")
    .map(e => {
      return (
        <a
          href={"#" + slugify(`${e.props.children}`, { remove: /[%]/g })}
          // onClick={handleClass}
          className="chapter"
        >
          {e.props.children[0]}
        </a>
      )
    })
  let rightContent = elements.map((el, i) => {
    if (el?.type === "h2") {
      return React.createElement(
        "h2",

        {
          id: slugify(`${el.props.children}`, { remove: /[%]/g }),
          className: "text-h2 right-el text-clr-primary text-fw-medium",
        },
        el.props.children
      )
    }
    return el
  })

  const header = React.createElement(
    "div",
    {
      className:
        "blog-body__chapters-left__header text-p1 text-clr-primary text-fw-medium",
    },
    "Chapters"
  )
  const titlewrap = React.createElement(
    "div",
    {
      className: "blog-body__chapters-left__links",
    },

    leftContent
  )
  const leftColWrap = React.createElement(
    "div",
    {
      className: "wrapper",
    },
    [header, titlewrap]
  )

  const innerlft = React.createElement(
    "div",
    { className: "blog-body__chapters-left" },

    leftColWrap
  )
  const content = React.createElement(
    "div",
    { className: "blog-body__chapters-right" },
    rightContent
  )
  const wrapper = React.createElement(
    "div",
    { className: "blog-body__chapters", key: i },
    innerlft,
    content
  )

  return wrapper
}
export default useResolveBlogText
