import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/layout"
import fb from "../images/fb.svg"
import tweet from "../images/tweet.svg"
import linkedIn from "../images/linkedIn.svg"
import Img from "gatsby-image/withIEPolyfill"
import { options } from "../components/blogItem"
import NotFoundPage from "./404"
import SEO from "../components/seo"
import "../styles/blog.scss"
import useResolveBlogText from "../hooks/useResolveBlogText"
import Progressbar from "../components/ProgressBar"
import { useEffect } from "react"

const Article = ({ data }) => {
  const blogText = useResolveBlogText(
    data.allContentfulBlogs?.edges[0]?.node.description
  )
  const nonChapterBlogText = renderRichText(
    data.allContentfulBlogs?.edges[0]?.node.description,
    options
  )
  useEffect(() => {
    document.querySelectorAll(".chapter").forEach(anchor => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault()
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        })
      })
    })
    const leftChapters = document.querySelectorAll(".chapter")
    const rightColEl = document.querySelectorAll(".right-el")
    /******************Observer code *********/
    const observer = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            leftChapters.forEach(el => {
              el.classList.remove("active")
              if (entry.target.innerHTML === el.innerHTML) {
                el.classList.add("active")
              }
            })
          }
        })
      }
      // {
      //     rootMargin: "-50% 0% -50% 0%",
      // }
    )
    rightColEl.forEach(card => observer.observe(card))
  }, [])
  if (!data.allContentfulBlogs.edges) {
    return <NotFoundPage />
  }

  let blogProps = data.allContentfulBlogs?.edges[0]?.node

  let publishedDate = new Date(blogProps.publishDate)

  // const articleText = () => {
  //   let text = blogProps.description
  //   text = renderRichText(text, options)
  //   return <div className="blog-body__description">{text}</div>
  // }

  return (
    <Layout data={{ headerType: "light" }}>
      <SEO
        title={blogProps.metaDescription}
        description={
          blogProps.seoMetaDescription
            ? blogProps.seoMetaDescription.seoMetaDescription
            : ""
        }
        image={`https:${blogProps.heroImage.fluid.src}`}
        pathname={blogProps.slug}
        article={true}
      />
      {blogProps.chapters && <Progressbar />}
      <div className="container article-container">
        <section className="blog-header test">
          <Link to={`/blog`}>
            <p className="title-tag">
              {blogProps.chapters ? "/ Articles" : "/ Blog"}
            </p>
          </Link>
          <h1 className="text-h1 text-fuu-regular gap-2x">{blogProps.title}</h1>
          <div className="blog-detail">
            <figure className="article-author">
              {blogProps?.author?.image && blogProps?.author?.image?.fluid && (
                <Img
                  className="article-author__image"
                  fluid={blogProps.author.image.fluid}
                />
              )}
              <figcaption className="article-author__details">
                <h5 className="article-author__name">
                  {blogProps.author.name}
                </h5>
                <h6 className="article-author__role">
                  {blogProps.author.title}
                </h6>
              </figcaption>
            </figure>
            <ul className="blog-detail__time">
              <li>
                DATE PUBLISHED{" "}
                <b>
                  (
                  {`${publishedDate.getDate()}/${publishedDate.getMonth()}/${publishedDate.getFullYear()}`}
                  )
                </b>
              </li>
              <li>
                READ TIME <b>(5MIN)</b>
              </li>
            </ul>
          </div>
          <div className="blog-body">
            <div className="blog-body__image">
              {blogProps.heroImage && blogProps.heroImage.fluid ? (
                <Img fluid={blogProps.heroImage.fluid} />
              ) : blogProps.heroImage && blogProps.heroImage.fixed ? (
                <Img fixed={blogProps.heroImage.fixed} />
              ) : (
                ""
              )}
            </div>

            <div
              className={
                blogProps.chapters ? "blog-body__chap" : "blog-body__content"
              }
            >
              {!blogProps.chapters && (
                <div className="blog-body__social">
                  <div className="blog-body__title">
                    {blogProps.title}
                    <span>
                      (
                      {`${publishedDate.getDate()}.${publishedDate.getMonth()}.${publishedDate.getFullYear()}`}
                      )
                    </span>
                  </div>

                  <ul className="social-media">
                    <li>
                      <a
                        href={
                          "https://www.facebook.com/sharer/sharer.php?u=https://coderapper.com/" +
                          blogProps.slug
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={fb} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          "https://twitter.com/intent/tweet?url=https://coderapper.com/" +
                          blogProps.slug +
                          "&text=" +
                          blogProps.title
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={tweet} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          "https://www.linkedin.com/shareArticle?mini=true&url=https://coderapper.com/" +
                          blogProps.slug
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedIn} />
                      </a>
                    </li>
                  </ul>
                </div>
              )}
              {blogProps.chapters ? (
                <div className="blog-body__text">{blogText}</div>
              ) : (
                <div className="non-chapter-blog">{nonChapterBlogText}</div>
              )}
            </div>
          </div>
        </section>
        {/* {blogProps.next.id &&
          <section className="blog-list">
            <h3 className="blog-list__title">Next article</h3>
            <div className="cdr-tab__content-item">
              <BlogItem
                key={blogProps.next.id}
                artitleId={blogProps.next.id}
                artitleSlug={blogProps.next.slug}
                artitleTitle={blogProps.next.title}
                articleImage={blogProps.next?.heroImage}
                author={blogProps.next.author?.name}
                authorTitle={blogProps.next.author?.title}
                authorImage={blogProps.next.author?.image}
                otherProps={{ ...blogProps.next }}
              />
            </div>
          </section>
        } */}
      </div>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String) {
    allContentfulBlogs(
      sort: { fields: publishDate }
      filter: { node_locale: { eq: "en-US" }, slug: { eq: $slug } }
    ) {
      edges {
        next {
          slug
          title
          author {
            image {
              fluid {
                srcSet
                src
              }
            }

            name
            title
          }
        }
        node {
          id
          node_locale
          tags
          title
          slug

          updatedAt
          createdAt
          publishDate
          heroImage {
            fluid {
              srcSet
              src
            }
            fixed {
              srcSet
              src
            }
            file {
              url
            }
          }
          author {
            company
            name
            title
            image {
              fluid {
                srcSet
                src
              }
              file {
                url
              }
            }
          }
          description {
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename

                fixed(width: 1400) {
                  width
                  height
                  srcSet
                  src
                }
                fluid {
                  srcSet
                  src
                }
              }
            }
            raw
          }
          chapters
          seoMetaDescription {
            seoMetaDescription
          }
          metaDescription
        }
      }
    }
  }
`
